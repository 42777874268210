var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"82fa7680f45c81e0971b5f397e74be9f265f0ea8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://f2bc25a20bbfdc09852e9bc0c9037a44@o1291986.ingest.us.sentry.io/4508442548568064',
  tracesSampleRate: 0.2,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
});
